import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import {
  ContractReservationsResult,
  ContractTrackingByReservationsPayload,
  ContractTrackingResult,
  ELDGPSDataResult,
  ReservationIdJourneyIdVehicleIdPairList,
  TrackingByIdResult,
} from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets tracking data by the given pairs of trip IDs and vehicle IDs.
   *
   * @param payload - The trip ID and vehicle ID pairs to use to get tracking data.
   * @returns A promise that resolves to the result containing the tracking data.
   */
  byIdPairsV2(
    payload: ReservationIdJourneyIdVehicleIdPairList
  ): Promise<AxiosResponse<TrackingByIdResult>> {

    const url = '/v2/tracking/data/filtered'
    return httpService.post<
      TrackingByIdResult,
      ReservationIdJourneyIdVehicleIdPairList
    >(url, payload)
  },
  byIdPairsV2Hash(
    payload: ReservationIdJourneyIdVehicleIdPairList
  ): Promise<AxiosResponse<TrackingByIdResult>> {

    const url = '/v2/tracking/dataByHash/filtered'
    return httpService.post<
      TrackingByIdResult,
      ReservationIdJourneyIdVehicleIdPairList
    >(url, payload)
  },
  /**
   * Gets the most recent enterprise tracking data.
   * @returns A promise that resolves to the result containing the most recent tracking data.
   */
  enterpriseMostRecentDataV2(): Promise<AxiosResponse<ELDGPSDataResult>> {
    const url = '/v2/tracking/recent/data'
    return httpService.get(url)
  },
  /**
   * Gets contract tracking data by the given hash and bounds.
   * @param hash - The hash to use to get contract tracking data.
   * @param isVanityUrl - Whether the hash is a vanity URL.
   * @param bounds - The bounds to use to get contract tracking data.
   * @returns A promise that resolves to the result containing the contract tracking data.
   */
  contractDataByHash(
    hash: string,
    isVanityUrl = false,
    bounds: { before: number; after: number } = { before: 30, after: 30 }
  ): Promise<AxiosResponse<ContractTrackingResult>> {
    const query = `minutesBefore=${bounds.before}&minutesAfter=${bounds.after}&isVanityUrl=${isVanityUrl}`
    const url = `/tracking/contract/${hash}?${query}`
    return httpService.get(url)
  },
  contractReservationDataByHash(
    hash: string,
    isVanityUrl = false,
    bounds: { before: number; after: number } = { before: 30, after: 30 }
  ): Promise<AxiosResponse<ContractReservationsResult>> {
    const query = `minutesBefore=${bounds.before}&minutesAfter=${bounds.after}&isVanityUrl=${isVanityUrl}`
    const url = `/reservations/contractTracking/${hash}?${query}`
    return httpService.get(url)
  },
  contractTrackingDataByReservations(
    payload: ContractTrackingByReservationsPayload
  ): Promise<AxiosResponse<ContractTrackingResult>> {
    const url = '/tracking/contractTracking'
    return httpService.post<
      ContractTrackingResult,
      ContractTrackingByReservationsPayload
    >(url, payload)
  },
}
