import { AxiosResponse } from 'axios'
import {
  NPSPayload,
  NPSHashPayload,
  NPSResult,
  NPSHashResult,
} from '@/models/dto'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Adds a new post-booking NPS (Net Promoter Score) submission.
   * @param payload - The payload containing the data for the new post-booking NPS submission.
   * @returns A promise that resolves to the result of the add operation, including the newly added NPS submission.
   */
  add(payload: NPSPayload): Promise<AxiosResponse<NPSResult>> {
    const url = '/nps'
    return httpService.post<NPSResult, NPSPayload>(url, payload)
  },

  /**
   * Checks to see if given hash for NPS submission is valid.
   * @returns A promise that resolves to the result of the get operation.
   */
  checkByHash(hash: string): Promise<AxiosResponse<NPSHashResult>> {
    const url = `/nps/hash/${hash}`
    return httpService.get(url)
  },

  /**
   * Adds a new post-trip NPS (Net Promoter Score) submission using given hash.
   * @param payload - The payload containing the data for the new post-trip NPS submission.
   * @returns A promise that resolves to the result of the add by hash operation, including the newly added NPS submission.
   */
  addByHash(
    hash: string,
    payload: NPSHashPayload
  ): Promise<AxiosResponse<NPSResult>> {
    const url = `/nps/hash/${hash}`
    return httpService.post<NPSResult, NPSHashPayload>(url, payload)
  },
}
