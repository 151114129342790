import Vue from 'vue'
import { AuthorizationParams, RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { Dictionary, Location, RawLocation, Route } from "vue-router/types/router";
import { SplitFeatureFlag } from "./enum";
import Auth0AppState from "@/models/dto/Auth0AppState";
import { IS_RESERVATION_INVITE_QUERY_PARAM } from "./constants";

/**
 * Builds the options required for redirecting to the login or sign up page.
 *
 * @param {any} appState - The state of the application to use when redirecting.
 * @param {string} email - (optional) The email address of the user to pre-fill the login or sign up form.
 * @returns The options for redirecting to the login or signup page.
 */
export const buildRedirectLoginOptions = (appState: any, email?: string): RedirectLoginOptions => {
  const authorizationParams: AuthorizationParams = {
    redirect_uri: `${window.location.origin}/auth-callback`
  }
  if (email) {
    authorizationParams.login_hint = email
  }
  return {
    appState,
    authorizationParams
  }
}

// === Begin Sign Up Redirect via Auth0 Utils === //

const getAuth0SignUpRedirect = (query: Dictionary<string | string[]>): Location => {
  const { redirectFrom, entityType } = query || {}
  const isReservationInvite = entityType === 'reservation'

  if (!redirectFrom) {
    return { name: 'quote-index' } as Location
  }

  const baseUrl = redirectFrom.toString()
  const hasExistingParams = baseUrl.includes('?')
  const paramPrefix = hasExistingParams ? '&' : '?'

  const signupFromReservationInviteParam = isReservationInvite ? `${IS_RESERVATION_INVITE_QUERY_PARAM}=true` : ''
  const toQuery = `${baseUrl}${paramPrefix}${signupFromReservationInviteParam}`
  return { path: toQuery } as Location
}

const buildAuth0SignUpAppState = (name: string, path: string): Auth0AppState => {
  const appState: Auth0AppState = {}
  if (name) {
    appState.routeName = name
  }
  if (path) {
    appState.redirectFrom = path
  }
  return appState
}

export const goToAuth0SignUpViaRedirect = async (query: Dictionary<string | string[]>, userHash: string, email: string): Promise<void> => {
  const isNewCustomer = !!userHash
  const redirectLocation: Location = getAuth0SignUpRedirect(query)
  const { name, path } = redirectLocation
  const appState = buildAuth0SignUpAppState(name, path)
  const options: RedirectLoginOptions = buildRedirectLoginOptions(appState, email)

  if (isNewCustomer) {
    await Vue.prototype.$auth0.signupWithRedirect(options)
  } else {
    await Vue.prototype.$auth0.loginWithRedirect(options)
  }
}

// ===     End Sign Up Redirect via Auth0     === //
