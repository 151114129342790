import { SalesBotQuote as SalesBotQuoteDTO } from '@/models/dto'
import { CompanyId, CurrencyType } from '@/utils/enum'
import { SalesBotCustomer } from './SalesBotCustomer'
import { SalesBotTrip } from './SalesBotTrip'

export class SalesBotQuote implements SalesBotQuoteDTO {
  currency = CurrencyType.USA
  customer = new SalesBotCustomer()
  companyId = CompanyId.CharterUP
  trips: SalesBotTrip[] = []
  selectedQuoteId: number = null
  selfServeId: string = null

  constructor(quote?: SalesBotQuoteDTO) {
    if (!quote) {
      this.trips.push(new SalesBotTrip())
    } else {
      this.customer = quote.customer
      this.trips = quote.trips.map((trip) => new SalesBotTrip(trip))
      this.selfServeId = quote.selfServeId
      this.selectedQuoteId = quote.selectedQuoteId
    }
  }

  toObject(): SalesBotQuoteDTO {
    const quote = Object.assign({}, this)
    quote.customer = Object.assign({}, this.customer)
    quote.trips = this.trips.map((trip) => trip.toObject()) as SalesBotTrip[]
    return quote
  }
}
