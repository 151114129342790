import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import {
  GenericApiResult,
  OnTimeStatusResult,
} from '@/models/dto'
import { JourneyStatus, JourneyStatusResponse } from '@/models/dto/OnTime'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets tracking data by the given pairs of trip IDs and vehicle IDs.
   *
   * @param payload - The journey IDs to use to get tracking data.
   * @returns A promise that resolves to the result containing on time status.
   */
  byJourneyIds(
    journeyIds: number[]
  ): Promise<AxiosResponse<OnTimeStatusResult>> {
    const url = '/onTime/calculateOnTimeStatuses'
    return httpService.post(url, journeyIds)
  },
  getJourneyStatusesV2(journeyIds: number[]): Promise<AxiosResponse<JourneyStatusResponse>> {
    const url = '/journeys/stopStatus'
    return httpService.post<JourneyStatusResponse, number[]>(url, journeyIds)
  }
}
