import Vue, { VueConstructor } from "vue";
import SplitIO from "@splitsoftware/splitio/types/splitio";
import { SplitFactory } from "@splitsoftware/splitio";
import { v4 } from "uuid";
import { datadogRum } from "@datadog/browser-rum";
import { SplitEvents, SplitFeatureFlag, SplitTrafficType } from "@/utils/enum";
import { splitKey } from "@/utils/env";
import { load, save } from "@/utils/localStorage";

let instance: Vue | null = null;
const SPLIT_SESSION_TOKEN_KEY = "split-session-token";

// If we want to support more traffic types, our multi-client handling must be updated.
const SPLIT_FLAG_TRAFFIC_MAP: { [key in SplitFeatureFlag]: SplitTrafficType } =
  {
    [SplitFeatureFlag.SalesBotRevamp]: SplitTrafficType.Anonymous,
    [SplitFeatureFlag.LiveChat]: SplitTrafficType.Anonymous,
    [SplitFeatureFlag.ItineraryModificationWizard]: SplitTrafficType.User,
    [SplitFeatureFlag.NewTripAmenities]: SplitTrafficType.User,
    [SplitFeatureFlag.SalesbotLastMinuteQuote]: SplitTrafficType.User,
    [SplitFeatureFlag.BrandedQuoteFlow]: SplitTrafficType.User,
    [SplitFeatureFlag.DisplayPartnerURL]: SplitTrafficType.User,
  };

/** Returns the current instance of the SDK */
export const useSplit = (): Vue => {
  /** Creates an instance of the Split SDK. If one has already been created, it returns that instance */
  if (instance) {
    return instance;
  }
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        userClient: null,
        anonymousClient: null,
      };
    },
    methods: {
      /** Use this method to instantiate the SDK client */
      async init(): Promise<void> {
        try {
          let sessionToken = load(SPLIT_SESSION_TOKEN_KEY);
          if (!sessionToken) {
            sessionToken = v4();
            save(SPLIT_SESSION_TOKEN_KEY, sessionToken);
          }

          // Initializes Split user client with logged-in user ID if available
          const userId = load("userId");
          if (!userId) {
            this.anonymousClient = await this.initClient(
              sessionToken,
              SplitTrafficType.Anonymous,
              this.anonymousClient
            );
            return;
          }

          // If one promise fails, the other will still resolve.
          const {
            0: userClientResult,
            1: anonymousClientResult,
          } = await Promise.allSettled([
            this.initClient(
              userId.toString(),
              SplitTrafficType.User,
              this.userClient
            ),
            this.initClient(
              sessionToken,
              SplitTrafficType.Anonymous,
              this.anonymousClient
            ),
          ]);

          this.userClient = userClientResult.status === "fulfilled" ? userClientResult.value : null;
          this.anonymousClient = anonymousClientResult.status === "fulfilled" ? anonymousClientResult.value : null;
        } catch (e) {
          // If something goes wrong with serializing the user ID, initialize the anonymous client
          console.warn(
            "Failed to serialize user ID and initialize Split client",
            e
          );
          this.anonymousClient = await this.initClient(
            "anonymous",
            SplitTrafficType.Anonymous,
            this.anonymousClient
          );
        }
      },
      async initClient(
        key: string,
        traffic: SplitTrafficType,
        client?: SplitIO.IBrowserClient
      ): Promise<SplitIO.IBrowserClient> {
        // Client must be destroyed before reinitializing
        if (client) {
          await this.destroyClient(client);
        }

        const splitFactory = this.createFactory(key, traffic);
        try {
          const newClient = splitFactory.client(key, traffic);
          await newClient.ready().catch((e) => {
            throw e;
          });
          return newClient;
        } catch (e) {
          console.warn(
            `Failed to initialize client for ${traffic} traffic: ${e}`
          );
        }

        return null;
      },
      async destroyClient(client?: SplitIO.IBrowserClient): Promise<void> {
        if (client) await client.destroy();
      },
      createFactory(
        key: string,
        trafficType: SplitTrafficType
      ): SplitIO.IBrowserSDK {
        const authorizationKey = splitKey();

        // Might want to swap back to polling if error logs show up again
        return SplitFactory({
          streamingEnabled: true,
          debug: false,
          core: {
            authorizationKey,
            trafficType,
            key,
          },
          startup: {
            readyTimeout: 15,
            requestTimeoutBeforeReady: 5,
            retriesOnFailureBeforeReady: 2,
          },
          storage: {
            type: "LOCALSTORAGE",
          },
          impressionListener: {
            logImpression(impressionData) {
              datadogRum.addFeatureFlagEvaluation(
                impressionData.impression.feature,
                impressionData.impression.treatment
              );
            },
          },
        });
      },
      getClient(flag: SplitFeatureFlag): SplitIO.IBrowserClient {
        const trafficType = SPLIT_FLAG_TRAFFIC_MAP[flag];
        if (!trafficType) {
          throw new Error(`${flag} does not have an associated traffic type`);
        }
        const client = trafficType === SplitTrafficType.User
          ? this.userClient
          : this.anonymousClient;

        if (!client) {
          console.error(`No client found for traffic type ${trafficType}, defaulting to anonymous`)
          return this.anonymousClient;
        }

        return client
      },
      /**
       * Check whether a feature flag's treatment is set to the value of on.
       * @param {SplitFeatureFlag} flag - The feature flag to check.
       * @returns {boolean} Whether the feature flag is enabled or not.
       */
      async isFeatureEnabled(flag: SplitFeatureFlag): Promise<boolean> {
        const client = this.getClient(flag);

        try {
          await client.ready().catch((e) => {
            throw e;
          });
          // SDK is now ready
          const treatment = await client.getTreatment(flag);
          return treatment === "on";
        } catch (e) {
          console.error("Split SDK has timed out getting treatment for", flag);
          return false;
        }
      },
      /**
       * Gets the dynamically configured treatment for a given feature flag.
       * https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations
       * @param {SplitFeatureFlag} flag - The feature flag to check.
       * @returns {SplitIO.TreatmentWithConfig} The treatment object.
       */
      async getTreatmentWithConfig(
        flag: SplitFeatureFlag
      ): Promise<SplitIO.TreatmentWithConfig> {
        const client = this.getClient(flag);
        try {
          await client.ready().catch((e) => {
            throw e;
          });
          // SDK is now ready
          return await client.getTreatmentWithConfig(flag);
        } catch (e) {
          console.error("Split SDK has timed out getting treatment for", flag);
          return null;
        }
      },
      /**
       * Updates the current Split key for the user client.
       * https://help.split.io/hc/en-us/articles/9648555765133-Foundational-concepts#traffic-type
       * @param {string} key - The unique key that is targeted via traffic type.
       * @returns void
       */
      async updateKey(key: string): Promise<void> {
        if (!key) {
          throw new Error("Invalid Split key");
        }
        this.userClient = await this.initClient(
          key,
          SplitTrafficType.User,
          this.userClient
        );
      },
      /**
       * Records a user action corresponding to an event type.
       * https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#track
       * @param {string} trafficType - The traffic type of the key in the track call.
       * @param {string} eventType - The event type corresponding to this event.
       * @param {number=} value - The value of this event.
       * @param {Properties=} properties - The properties of this event. Values can be string, number, boolean or null.
       * @returns {boolean} Whether the event was queued to be sent to Split successfully or not.
       */
      async track(
        trafficType: SplitTrafficType,
        eventType: SplitEvents,
        value?: number,
        properties?: SplitIO.Properties
      ): Promise<boolean> {
        const client =
          trafficType === SplitTrafficType.User
            ? this.userClient
            : this.anonymousClient;

        try {
          await client.ready().catch((e) => {
            throw e;
          });
          // SDK is now ready
          return await client.track(eventType, value, properties);
        } catch (e) {
          console.error("Split SDK has timed out tracking", eventType);
          return false;
        }
      },
    },
  });
  return instance;
};

export const SplitPlugin = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$split = useSplit();
  },
};

Vue.use(SplitPlugin);
