<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex margin-b-4"
      :class="
        $vuetify.breakpoint.xsOnly ? 'flex-column align-start' : 'align-center'
      "
    >
      <h2>Riders</h2>
      <div class="d-flex grow" />
      <div class="d-flex align-center margin-b-3">
        <CUSelect
          v-model="onboardStatus"
          :items="onboardStatusMap"
          item-text="label"
          item-value="value"
          placeholder="Status Filter"
          outlined
          flat
          dense
          hide-details
          clearable
          read-only
          :style="{ 'margin-top': '7px' }"
          class="margin-r-3 w-236"
        />
        <v-text-field
          id="organization-search"
          v-model="search"
          solo
          outlined
          :append-icon="search ? 'close' : 'search'"
          flat
          dense
          hide-details
          class="margin-r-3"
          placeholder="Search users"
          @click:append="clearSearch"
        />
      </div>
      <div class="d-flex align-center margin-b-3">
        <OrganizationAddRiderDialog
          id="organization-add-user"
          :customer-account="customerAccount"
          @success="updateTable"
        />
      </div>
      <div class="d-flex align-center margin-b-3 margin-r-3">
        <v-btn
          id="import-rider-modal-btn"
          small
          outlined
          class="text-primary border-primary"
          @click="toggleImportRiderModal(true)"
        >
          Import
        </v-btn>
      </div>
      <div
        v-if="customerAccount.hasRiderIdTickets"
        class="d-flex align-center margin-b-3"
      >
        <v-btn small color="primary" @click="downloadQRCodes">
          Download QR Codes
        </v-btn>
      </div>
    </div>
    <CUDataTable
      id="rider-index-table"
      ref="parentRiderTable"
      :items="items"
      :columns="filteredColumns"
      :actions="actions"
      :options.sync="tableOptions"
      :server-items-length="itemsLength"
      mobile-view-on-breakpoint="xs"
      :loading="false"
      class="rider-data-table"
      item-key="employeeId"
      :hide-default-footer="itemsLength <= tableOptions.itemsPerPage"
      :hide-default-header="$vuetify.breakpoint.xsOnly"
      v-on="$listeners"
      @update:options="updateTable"
    >
      <template #mobileLayout="{ item }">
        <tr>
          <td class="padding-t-4 padding-b-6 padding-r-8">
            <v-row class="font-16 padding-y-2">
              <b>{{ item.name }}</b>
            </v-row>
            <v-row>
              <b class="margin-r-1">Employee ID:</b>
              {{ item.employeeId }}
            </v-row>
            <v-row class="margin-t-4">
              <b class="margin-r-1">Created On:</b>
              {{ formattedDatetime(item.createdOn) }}
            </v-row>
            <v-row class="margin-t-4">
              <b class="margin-r-1">Status:</b>
              <div v-if="!!item.userLastLogin" class="font-14">
                Onboarded
              </div>
              <div v-else-if="item.inviteSentOn && !item.userLastLogin" class="font-14">
                Invited
              </div>
              <div v-else-if="!item.userLastLogin && !item.inviteSentOn" class="font-14">Not Onboarded</div>
            </v-row>
            <v-row class="margin-t-2">
              <v-col class="padding-a-0 d-flex justift-start align-end">
                <a
                  v-if="item.email"
                  :href="`mailto: ${item.email}`"
                  class="text-primary font-14"
                >
                  {{ item.email }}
                </a>
                <p v-else class="font-12">
                  No email found
                </p>
              </v-col>
              <v-col class="padding-a-0 d-flex justify-end align-end">
                <p v-if="item.phone" class="font-14">
                  {{ formattedPhoneNumber(item.phone) }}
                </p>
                <p v-else class="font-12">
                  No phone found
                </p>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </CUDataTable>
    <OrganizationImportRiderDialog
      id="organization-import-user"
      :value="importRiderDialogIsOpen"
      @input="toggleImportRiderDialog($event)"
    />
    <OrganizationEditRiderDialog
      id="organization-edit-user"
      :value="editRiderDialogIsOpen"
      :rider="currentRider"
      :customer-account="customerAccount"
      @input="toggleEditRider($event)"
      @success="updateTable"
    />
    <OrganizationDeleteRiderDialog
      id="organization-delete-user"
      :value="deleteRiderDialogIsOpen"
      :rider="currentRider"
      @input="toggleDeleteRider($event)"
      @success="updateTable"
    />
    <ImportRiderModal
      id="import-rider-modal"
      v-model="importRiderModalIsOpen"
      :customer-account="customerAccount"
      @input="toggleImportRiderModal($event)"
      @open-import-dialog="toggleImportRiderDialog(true)"
    />
    <RiderQRCodeModal
      id="rider-qr-code-modal"
      v-model="riderQrCodeModalIsOpen"
      :rider="currentRider"
      @input="toggleRiderQRCode($event)"
    />
    <RiderHistoricalDataModal
      id="rider-historical-data-modal"
      v-model="riderHistoricalModalIsOpen"
      :rider="currentRider"
      @input="toggleRiderQRCode($event)"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import rider from '@/services/rider'
import { filter } from '@/utils/filter'
import { phoneFormatFilter } from '@/utils/phone'
import { CustomerAccount } from '@/models/dto'
import OrganizationImportRiderDialog from '@/components/OrganizationImportRiderDialog.vue'
import OrganizationAddRiderDialog from '@/components/OrganizationAddRiderDialog.vue'
import OrganizationEditRiderDialog from '@/components/OrganizationEditRiderDialog.vue'
import OrganizationDeleteRiderDialog from '@/components/OrganizationDeleteRiderDialog.vue'
import ImportActionsDropdown from '@/components/ImportActionsDropDown.vue'
import RiderQRCodeModal from '@/components/RiderQRCodeModal.vue'
import CUDataTable from '@/components/CUDataTable.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewRider } from '@/models/dto/TableViewRider'
import ImportRiderModal from './ImportRiderModal.vue'
import RiderHistoricalDataModal from './RiderHistoricalDataModal.vue'
import { saveAs } from 'file-saver'

@Component({
  components: {
    OrganizationImportRiderDialog,
    OrganizationAddRiderDialog,
    OrganizationEditRiderDialog,
    OrganizationDeleteRiderDialog,
    ImportActionsDropdown,
    CUDataTable,
    ImportRiderModal,
    RiderQRCodeModal,
    RiderHistoricalDataModal,
  },
})
export default class OrganizationRiderData extends Vue {
  search = ''
  onboardStatus = null
  debounce =  undefined

  importRiderDialogIsOpen = false
  editRiderDialogIsOpen = false
  deleteRiderDialogIsOpen = false
  importRiderModalIsOpen = false
  riderQrCodeModalIsOpen = false
  riderHistoricalModalIsOpen = false

  currentRider = {}

  expanded = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '5695bfb4-bfc1-43ab-a4c8-e912b429249f',
      elementId: 'employeeId',
      text: 'Employee ID',
      value: 'employeeId',
      type: 'text',
    },
    {
      _t_id: '178a59b9-2736-4a23-a454-42255f89c893',
      elementId: 'name',
      text: 'Name',
      value: 'name',
      type: 'text',
      computedText: (row: TableViewRider) =>
        `${row.firstName || ''} ${row.lastName || ''}`,
    },
    {
      _t_id: '72c7b06c-eb5a-4714-88dc-ad91ce2318d6',
      elementId: 'email',
      text: 'Email Address',
      value: 'email',
      type: 'text',
    },
    {
      _t_id: '74f56726-5222-4fb6-979b-ea571e0dddcd',
      elementId: 'phone',
      text: 'Phone',
      value: 'phone',
      type: 'text',
    },
    {
      _t_id: 'b345567b-db78-4b16-bae4-03ebe47b6db0',
      elementId: 'group',
      text: 'Group',
      value: 'group',
      type: 'text',
    },
    {
      _t_id: 'a1d7c532-9467-4e84-bca0-38fb5800bd10',
      elementId: 'createdOn',
      text: 'Created On',
      value: 'createdOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.createdOn),
    },
    {
      _t_id: '4ce52bcc-926b-4fe9-b572-98cd1d6a9910',
      elementId: 'onboardedOn',
      text: 'Onboarded On',
      value: 'onboardedOn',
      type: 'text',
      computedText: (row: TableViewRider) =>
        this.formattedDatetime(row.onboardedOn),
    },
    {
      _t_id: '045f3b44-8e12-4860-b80b-cb2b03587433',
      elementId: 'status',
      text: 'Status',
      value: 'status',
      type: 'text',
      classes: 'font-medium',
      computedText: (row: TableViewRider): string => {
        if (!!row.userLastLogin) {
          return 'Onboarded'
        }
        if (!!row.inviteSentOn && !row.userLastLogin) {
          return 'Invited'
        }
        if (!row.userLastLogin && !row.inviteSentOn) {
          return 'Not Onboarded'
        }
      },
    },
    {
      _t_id: '05855847-aab2-4ab7-bcdd-97f9c0fe0d07',
      elementId: 'actions',
      text: '',
      value: 'actions',
      type: 'actions',
    },
  ]

  sendRiderInviteAction = {
    color: 'gray-light',
    icon: 'send',
    iconViewBox: '-2 -2 28 28',
    textClasses: 'font-14 ',
    action: (row: TableViewRider): Promise<void> =>
      this.sendRiderEmail(row.riderId),
  }

  actions = [
    {
      displayText: 'Send Rider Invite',
      key: 'send-rider-invite',
      hideOn: (row: TableViewRider): boolean =>
        !!row.userLastLogin || row.inviteSentOn !== null,
      ...this.sendRiderInviteAction,
    },
    {
      displayText: 'Resend Rider Invite',
      key: 'resend-rider-invite',
      hideOn: (row: TableViewRider): boolean =>
        !!row.userLastLogin || row.inviteSentOn === null,
      ...this.sendRiderInviteAction,
    },
    {
      displayText: 'Edit',
      key: 'edit',
      color: 'primary',
      icon: 'edit',
      iconViewBox: '0 0 24 24',
      ariaLabel: 'Edit',
      textClasses: 'font-14 ',
      action: (row: TableViewRider): void => {
        this.openEditRider(row.riderId)
      },
    },
    {
      displayText: 'Delete',
      key: 'delete',
      color: 'error',
      icon: 'delete',
      iconViewBox: '0 0 24 24',
      ariaLabel: 'Delete',
      textClasses: 'font-14 ',
      action: (row: TableViewRider): void => {
        this.openDeleteRider(row.riderId)
      },
    },
  ]

  items = []
  itemsLength = 0
  onboardStatusMap = [
    {
      label: 'Onboarded',
      value: 'onboarded',
    },
    {
      label: 'Invited',
      value: 'invited',
    },
    {
      label: 'Not Onboarded',
      value: 'not_onboarded',
    },
  ]

  get filteredColumns(): DataTableColumn[] {
    return this.columns.filter((column) => {
      const isRiderGroup = column.elementId === 'group'
      return (
        !isRiderGroup || (isRiderGroup && this.customerAccount?.hasRiderGroups)
      )
    })
  }

  mounted() {
    if (this.customerAccount.hasRiderIdTickets) {
      this.actions.push(
        {
          displayText: 'Historical Data',
          key: 'historical-data',
          color: 'primary',
          icon: 'description',
          iconViewBox: '0 0 24 24',
          ariaLabel: 'Historical Data',
          textClasses: 'font-14 ',
          action: (row: TableViewRider): void => {
            this.openHistoricalData(row)
          },
        },
        {
          displayText: 'Rider QR Code',
          key: 'qr_code',
          color: 'black',
          icon: 'qr_code_2',
          iconViewBox: '0 0 24 24',
          ariaLabel: 'QR Code',
          textClasses: 'font-14 ',
          action: (row: TableViewRider): void => {
            this.openRiderQRCode(row)
          },
        }
      )
    }
  }

  async downloadQRCodes(): Promise<void> {
    const response = await rider.getQRCodes(
      this.customerAccount.customerAccountId
    )
    await saveAs(response.data, 'RiderQRCodes.zip')
  }

  async toggleImportRiderDialog(value: boolean): Promise<void> {
    this.importRiderDialogIsOpen = value
    if (!value) {
      await this.updateTable()
    }
  }

  openEditRider(riderId: number): void {
    this.editRiderDialogIsOpen = true
    this.currentRider = this.items.find((rider) => rider.riderId === riderId)
  }

  toggleEditRider(value: boolean): void {
    this.editRiderDialogIsOpen = value
    if (!value) {
      this.currentRider = {}
    }
  }

  openDeleteRider(riderId: number): void {
    this.deleteRiderDialogIsOpen = true
    this.currentRider = this.items.find((rider) => rider.riderId === riderId)
  }

  toggleDeleteRider(value: boolean): void {
    this.deleteRiderDialogIsOpen = value
    if (!value) {
      this.currentRider = {}
    }
  }

  toggleImportRiderModal(value: boolean): void {
    this.importRiderModalIsOpen = value
  }

  toggleRiderQRCode(value: boolean): void {
    this.riderQrCodeModalIsOpen = value
    if (!value) {
      this.currentRider = {}
    }
  }

  openRiderQRCode(rider: TableViewRider): void {
    this.currentRider = rider
    this.toggleRiderQRCode(true)
  }

  toggleRiderHistoricalModal(value: boolean): void {
    this.riderHistoricalModalIsOpen = value
    if (!value) {
      this.currentRider = {}
    }
  }

  openHistoricalData(rider: TableViewRider): void {
    this.currentRider = rider
    this.toggleRiderHistoricalModal(true)
  }

  async sendRiderEmail(riderId: number): Promise<void> {
    const payload = {
      riderId,
    }
    try {
      await rider.sendInviteEmail(payload)
    } catch (e) {
      console.error(e)
    }
  }

  created(): void {
    this.updateTable()
  }

  clearSearch(): void {
    if (this.search) {
      this.search = ''
    }
  }

  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  @Watch('search')
  async searchChanged(): Promise<void> {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(async () => {
      await this.updateTable()
      if (this.search !== '') {
        const parentRiderTable = this.$refs.parentRiderTable as any
        this.expanded = parentRiderTable.serverItemsLength > 0
      } else {
        this.expanded = false
      }

      this.tableOptions.page = 1
    }, 300)
  }

  @Watch('onboardStatus')
  async onboardStatusChanged(): Promise<void> {
    await this.updateTable()
  }

  formattedPhoneNumber(phone): string {
    return phoneFormatFilter(phone)
  }

  formattedDatetime(datetime): string {
    return datetime ? this.$dayjs(datetime).format('MM/DD/YYYY h:mm A z') : ''
  }

  async updateTable(): Promise<void> {
    const employeeIdSearchFilter = {
      column: {
        _t_id: 'text_search_cust_1',
        value: 'employeeId',
        filterType: 'contains',
      },
      value: this.search,
    }
    const firstNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust_2',
        value: 'firstName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const lastNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust_3',
        value: 'lastName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const emailSearchFilter = {
      column: {
        _t_id: 'text_search_cust_4',
        value: 'email',
        filterType: 'contains',
      },
      value: this.search,
    }

    const customerDataFilter = filter()
    const filterGrandParentAnd = customerDataFilter.createParent('and')
    const filterParentOr = customerDataFilter.createParent('or')

    if (this.search) {
      customerDataFilter.add(filterGrandParentAnd, filterParentOr)
      customerDataFilter.add(filterParentOr, employeeIdSearchFilter)
      customerDataFilter.add(filterParentOr, firstNameSearchFilter)
      customerDataFilter.add(filterParentOr, lastNameSearchFilter)
      customerDataFilter.add(filterParentOr, emailSearchFilter)
    }

    const filters = customerDataFilter.asQueryParams()

    /** If there is any status filter set, conduct the status filtering on the front end.
     *  There are currently three different statuses: onboarded, invited, and not onboarded
     *  The logic is as follows:
     *  - If onboarded is selected, only show riders that have a userLastLogin
     *  - If invited is selected, only show riders that have an inviteSentOn and no userLastLogin
     *  - If not onboarded is selected, only show riders that have no userLastLogin and no inviteSentOn
     *  Pagination must also be conducted on the front end for this any of the status scenarios.
     *  NOTE: It is ok to do this filtering in conjuction with server side filtering like the search.
    */
    if (!!this.onboardStatus) {
      const { data: { resultList },
      } = await rider.tableView({
        filters,
        page: -1,
        pageSize: -1,
      })
      if (this.onboardStatus === 'onboarded') {
        this.items = resultList.filter((item) => !!item.userLastLogin)
      } else if (this.onboardStatus === 'invited') {
        this.items = resultList.filter((item) => !!item.inviteSentOn && !item.userLastLogin)
      } else if (this.onboardStatus === 'not_onboarded') {
        this.items = resultList.filter((item) => !item.userLastLogin && !item.inviteSentOn)
      }
      this.itemsLength = this.items.length
      this.items = this.items.slice((10*(this.tableOptions.page - 1)), (10*(this.tableOptions.page - 1) + this.tableOptions.itemsPerPage))
    } else {
      const {
        data: { resultList, count },
      } = await rider.tableView({
        filters,
        page: this.tableOptions.page,
        pageSize: this.tableOptions.itemsPerPage,
      })
      this.itemsLength = count
      this.items = resultList
    }
    this.items = this.items.map((item) => {
      return {
        ...item,
        role: 'Admin',
        name:
          item.firstName && item.lastName
            ? `${item.firstName} ${item.lastName}`
            : '',
        sentEmailStatus: 'unsent',
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.rider-data-table::v-deep {
  td,
  th {
    line-height: 21px;
    height: 60px;
    font-size: 0.875rem !important;
  }

  .last-quote-col {
    text-align: right;
    padding-right: 40px !important;
  }

  .padding-left-40 {
    padding-left: 44px;
  }

  tbody > tr {
    td:nth-child(1) {
      width: 20%;
    }
    td:nth-child(2) {
      width: 20%;
    }
    td:nth-child(3) {
      width: 15%;
    }
    td:nth-child(4) {
      width: 15%;
    }
    td:nth-child(5) {
      width: 15%;
    }
    td:nth-child(6) {
      width: 15%;
    }
  }
}

.v-data-table {
  &.mobile {
    margin-bottom: 0;
    ::v-deep .v-data-table__wrapper {
      border-radius: 0px;
      border-width: 0px 0px 1px 0px !important;
      table {
        tbody {
          tr {
            border: none;
            td {
              border-width: 0px 0px 1px 0px !important;
            }
          }
        }
      }
    }
  }
}

.v-text-field {
  &.v-input.v-select {
    ::v-deep .v-input__control > .v-input__slot {
      margin-bottom: 0 !important;
    }
  }

  ::v-deep .v-input__control {
    .v-input__slot {
      margin-bottom: 0 !important;
      .v-input__icon--append {
        margin-right: 8px;
        margin-top: -4px;
        .v-icon {
          color: rgba($black, 0.25);
        }
      }
    }
  }
}

.v-btn > input[type='file'] {
  display: none;
}
</style>
