import {AxiosResponse} from 'axios'
import {HttpService} from './common/HttpService'
import {EstimateDropoffTimesRequest, EstimateDropoffTimesResponse} from '@/models/dto/SalesbotEstimatedDropoffTimes'
import {
  AmenitySelectionOptionsRequest,
  AmenitySelectionOptionsResponse,
  VehicleSelectionOptionsRequest,
  VehicleSelectionOptionsResponse
} from '@/models/dto/SalesbotV2VehicleOptions'
import { CreateQuoteRequest, SalesbotV2QuoteRequest } from "@/models/dto/SalesbotV2QuoteRequest";
import {SalesBotQuoteResponse} from "@/models/SalesbotV2Quote";
import {SalesBotRequirementsReq, SalesBotRequirementsRes} from "@/models/dto/SalesBotRequirements";

const httpService: HttpService = new HttpService()

export default {
  /**
   * Fetches a quote for a given quote id.
   *
   * @param quoteId - The id of the quote to be fetched.
   * @returns A promise that resolves to an AxiosResponse containing the quote.
   */
  getQuote(
    quoteExternalId: string
  ): Promise<AxiosResponse<SalesbotV2QuoteRequest>> {
    const url = `/charterup/v1/salesbot/quote/${quoteExternalId}`
    return httpService.get(url)
  },
  /**
   * Estimates the dropoff times for a given request payload.
   *
   * @param payload - The request payload containing the necessary data to estimate dropoff times.
   * @returns A promise that resolves to an AxiosResponse containing the estimated dropoff times.
   */
  estimateDropoffTimes(
    payload: EstimateDropoffTimesRequest
  ): Promise<AxiosResponse<EstimateDropoffTimesResponse>> {
    const url = '/charterup/v1/salesbot/estimate-dropoff-times'
    return httpService.post(url, payload)
  },
  /**
   * Gets the special requirement options for given stop addresses and trip event type (ex: ADA, SPAB)
   *
   * @param payload - The request payload containing the necessary data to get special requirement options.
   * @returns A promise that resolves to an AxiosResponse continaing the special requirement options.
   */
  determineRequirements(
    payload: SalesBotRequirementsReq
  ): Promise<AxiosResponse<SalesBotRequirementsRes>> {
    const url = '/charterup/v1/salesbot/requirements'
    return httpService.post(url, payload)
  },
  /**
   * Gets the vehicle selection options for given salesbot itinerary and trip details.
   *
   * @param payload - The request payload containing the necessary data to get vehicle selection options.
   * @returns A promise that resolves to an AxiosResponse containing the vehicle selection options.
   */
  getVehicleSelectionOptions(
    payload: VehicleSelectionOptionsRequest
  ): Promise<AxiosResponse<VehicleSelectionOptionsResponse>> {
    const url = '/charterup/v1/salesbot/vehicle-selection'
    return httpService.post(url, payload)
  },
  /**
   * Gets the amenity selection options for given vehicle details.
   *
   * @param payload - The request payload containing the necessary data to get amenity selection options.
   * @returns A promise that resolves to an AxiosResponse containing the amenity selection options.
   */
  getAmenitySelectionOptions(
    payload: AmenitySelectionOptionsRequest
  ): Promise<AxiosResponse<AmenitySelectionOptionsResponse>> {
    const url = '/charterup/v1/salesbot/amenity-selection'
    return httpService.post(url, payload)
  },
  /**
   * Creates and/or updates a quote for the Sales Bot flow.
   *
   * @param payload - The request payload containing the necessary data to create a quote.
   * @returns A promise that resolves to an AxiosResponse containing the ID of the quote.
   */
  saveQuote(
    payload: CreateQuoteRequest
  ): Promise<AxiosResponse<SalesBotQuoteResponse>> {
    const url = '/charterup/v1/salesbot/quote'
    return httpService.post(url, payload)
  },
}
