import { AxiosResponse } from 'axios'
import {
  ApiResult,
  AuthPayload,
  AuthResult,
  ForgotPasswordPayload,
  ForgotPasswordResult,
  ResetPasswordPayload,
  ResetPasswordResult,
  SetPasswordPayload,
  UserProfileResult,
  CustomerValidatePayload,
  CustomerValidateResult,
  JwtAuthPayload, ValidatePasswordResponse, ValidatePasswordRequest,
} from '@/models/dto'
import { HttpService } from './common/HttpService'
import { MfaSmsPayload } from '@/models/dto/MfaSms'

const httpService: HttpService = new HttpService()
export default {
  /**
   * Attempts to log in with the given authentication payload.
   *
   * @param payload - The authentication information to be used for the login attempt.
   * @returns A promise that resolves to the result of the login attempt.
   */
  login(payload: AuthPayload): Promise<AxiosResponse<AuthResult>> {
    const url = '/customer-auth'
    return httpService.post<AuthResult, AuthPayload>(url, payload)
  },
  /**
   * Attempts to log in with the given JWT.
   *
   * @param payload - The authentication information to be used for the login attempt.
   * @returns A promise that resolves to the result of the login attempt.
   */
  delegatedJwtLogin(payload: JwtAuthPayload): Promise<AxiosResponse<AuthResult>> {
    const url = '/auth/jwt/login'
    return httpService.post<AuthResult, JwtAuthPayload>(url, payload)
  },
  /**
   * Attempts to validate the given customer information.
   *
   * @param payload - The customer information to be validated.
   * @returns A promise that resolves to the result of the validation attempt.
   */
  validateCustomer(
    payload: CustomerValidatePayload
  ): Promise<AxiosResponse<CustomerValidateResult>> {
    const url = '/customers/validate'
    return httpService.post<CustomerValidateResult, CustomerValidatePayload>(
      url,
      payload
    )
  },
  /**
   * Sends the twilio verification code for an anonymous user (only phone number)
   * @param payload - The payload containing the updated SMS information.
   * @returns A promise that resolves to the API result.
   */
  requestSmsOtp(
    payload: MfaSmsPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = '/auth/requestSmsOtp'
    return httpService.post<ApiResult, MfaSmsPayload>(url, payload)
  },
  /**
   * Retrieves the current user's roles and permissions.
   *
   * @returns A promise that resolves to the user's roles and permissions.
   */
  getUserRolesAndPermissions(): Promise<AxiosResponse<UserProfileResult>> {
    const url = '/userProfile'
    return httpService.get(url)
  },
  /**
   * Initiates the process of resetting a forgotten password.
   *
   * @param payload - The information needed to reset the password.
   * @returns A promise that resolves to the result of the password reset request.
   */
  forgotPassword(
    payload: ForgotPasswordPayload
  ): Promise<AxiosResponse<ForgotPasswordResult>> {
    const url = '/customers/customerForgotPassword'
    return httpService.post<ForgotPasswordResult, ForgotPasswordPayload>(
      url,
      payload
    )
  },
  /**
   * Attempts to reset a password using the given reset token and new password.
   *
   * @param payload - The information needed to reset the password.
   * @returns A promise that resolves to the result of the password reset attempt.
   */
  resetPassword(
    payload: ResetPasswordPayload
  ): Promise<AxiosResponse<ResetPasswordResult>> {
    const url = '/password/reset'
    return httpService.post<ResetPasswordResult, ResetPasswordPayload>(
      url,
      payload
    )
  },
  /**
   * Sets a new password for the current user.
   *
   * @param payload - The new password to be set.
   * @returns A promise that resolves to the result of the password update.
   */
  setPassword(payload: SetPasswordPayload): Promise<AxiosResponse<ApiResult>> {
    const url = '/password/set'
    return httpService.post(url, payload)
  },
  /**
   * Validates password against endpoint in Auth service
   *
   * @param passwordCandidate - new password chosen by the user
   * @returns A promise with the result of validation
   */
  validatePassword(passwordCandidate: string): Promise<AxiosResponse<ValidatePasswordResponse>> {
    const url = '/password/validate'
    return httpService.post<ValidatePasswordResponse, ValidatePasswordRequest>(url, { newPassword: passwordCandidate })
  },
  /**
   * Retrieves the user's profile information from the API using the JWT token.
   *
   * @returns A promise that resolves to an AxiosResponse with the user's profile information.
   */
  getUserProfileWithToken(): Promise<AxiosResponse<AuthResult>> {
    const url = '/auth/jwt'
    return httpService.post(url, {})
  }
}
