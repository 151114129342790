import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import {
  TripModificationTrip,
  WizardVehicle,
  TripModificationReviewResponse,
  TripModificationConfirmResponse
} from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Fetches the necessary information for a given reservation's managed ID to use for
   * trip itinerary modification.
   *
   * @param managedId - The reservation managed ID to use to get the trip itinerary information.
   * @returns A promise that resolves to the result of fetching the trip itinerary information.
   */
  start(
    managedId: string
  ): Promise<AxiosResponse<TripModificationTrip>> {
    const url = `/charterup/v1/trip-modification-wizard/start/${managedId}`
    return httpService.get(url)
  },

  /**
   * Fetches vehicle configurations based on the provided payload.
   *
   * @param payload - The payload containing the following properties:
   * - `passengerCount` (number): The number of passengers.
   * - `firstPickupLat` (number): The latitude of the first pickup location.
   * - `firstPickupLng` (number): The longitude of the first pickup location.
   * - `firstPickupTime` (string): The time of the first pickup.
   * - `lastDropoffTime` (string): The time of the last dropoff.
   * - `ada` (boolean): Indicates if ADA (Americans with Disabilities Act) compliant vehicles are required.
   *
   * @returns A promise that resolves to an AxiosResponse containing a 2D array of WizardVehicle objects.
   */
  vehicleConfigurations(payload: { passengerCount: number; firstPickupLat: number; firstPickupLng: number; firstPickupTime: string; lastDropoffTime: string; ada: boolean }): Promise<AxiosResponse<WizardVehicle[][]>> {
    const { passengerCount, firstPickupLat, firstPickupLng, firstPickupTime, lastDropoffTime, ada } = payload
    const query = encodeURI(`?firstPickupLat=${firstPickupLat}&firstPickupLng=${firstPickupLng}&firstPickupTime=${firstPickupTime}&lastDropoffTime=${lastDropoffTime}&ada=${ada}`)
    const url = `/charterup/v1/trip-modification-wizard/start/vehicle-configurations/${passengerCount}${query}`

    return httpService.get<WizardVehicle[][], unknown>(url)
  },

  /**
   * Calculates the difference between a requested trip and the current trip.
   * and determines if the request can be auto-accepted or not
   *
   * @param payload - The managed ID and the trip changes to compare against.
   * @returns A promise that resolves to the result of computing the trip changes.
   */
  review(
    payload: {
      managedId: string
      requestedTrip: TripModificationTrip
    }
  ): Promise<AxiosResponse<TripModificationReviewResponse>> {
    const url = '/charterup/v1/trip-modification-wizard/review'
    return httpService.post(url, payload)
  },

  /**
   * Submits and saves a trip modification request. Upon success, we return
   * the trip modification ID, trigger Sprinklr ticket creation, and send an
   * email to the customer alerting them of their request.
   *
   * @param payload - The managed ID and the requested trip changes to confirm.
   * @returns A promise that resolves to the result of confirming the trip modification.
   */
  confirm(
    payload: {
      managedId: string
      requestedTrip: TripModificationTrip
      priceDifference: number,
      newCustomerPaymentProfileId: number
    }
  ): Promise<AxiosResponse<TripModificationConfirmResponse>> {
    const url = '/charterup/v1/trip-modification-wizard/confirm'
    return httpService.post(url, payload)
  },

    /**
   * Applies a trip modification request. Upon success, we send an
   * email to the customer alerting them of their request.
   *
   * @param payload - The managed ID and the requested trip changes to apply.
   * @returns A promise that resolves to the result of confirming the trip modification.
   */
    apply(
      payload: {
        managedId: string
        requestedTrip: TripModificationTrip
      }
    ): Promise<AxiosResponse<void>> {
      const url = '/charterup/v1/trip-modification-wizard/apply'
      return httpService.post(url, payload)
    },

  /**
   * Loads an existing trip modification request.
   *
   * @param managedId - The managed ID and the requested trip changes to confirm.
   * @returns A promise that resolves to the result of confirming the trip modification.
    */
  success(
    managedId: string
  ): Promise<AxiosResponse<TripModificationReviewResponse>> {
    const url = `/charterup/v1/trip-modification-wizard/success/${managedId}`
    return httpService.get(url)
  },
}
