// Uncomment next line to debug in Safari
// import devtools from '@vue/devtools'

// Initialize Datadog before anything else
import { initDatadog } from './utils/datadog'

// Vue and plugins
import Vue, {provide} from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import VueTheMask from 'vue-the-mask'
import { createPinia, PiniaVuePlugin } from 'pinia'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import router from '@/router'
import cuLibrary from '@/plugins/cuLibrary'
import dayjs from '@/plugins/dayjs'
import '@/plugins/split'
import '@/plugins/auth0'
import '@/plugins/ga4'
import { provideAuth0 } from '@/composables/useAuth0'
import { splitInjectionKey } from "@/composables/useSplit";
import { injectionKey as ga4InjectionKey } from "@/composables/useGoogleAnalytics";
import LoadScript from 'vue-plugin-load-script'
import VCalendar from 'v-calendar'

// Global styles
import '@/scss/main.scss'

// Other plugins
import '@/plugins/axios'
import '@/plugins/gmaps'

// App component and store modules
import App from '@/App.vue'
import modules from '@/store/modules'

// Scoped slots hack
import applyScopedSlotsHack from '@/plugins/scopedSlotsHack'

import { SplitFeatureFlag, SplitTrafficType } from "@/utils/enum";
import { load } from "@/utils/localStorage";
import { getTokenProvider, TokenProvider } from '@/services/authTokenRepository'

// before anything else, initialize datadog integration
initDatadog()

// Configurations
Vue.prototype.$dayjs = dayjs
Vue.config.productionTip = false

// Use plugins
Vue.use(LoadScript as any)
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueTheMask)
Vue.use(cuLibrary)
Vue.use(PiniaVuePlugin)
Vue.use(VCalendar, { componentPrefix: 'vc' })

const pinia = createPinia()

// Apply scoped slots hack
applyScopedSlotsHack(Vue)

const initApp = async (): Promise<void> => {
  await Vue.prototype.$split.init()
  await Vue.prototype.$auth0.init()

  // Initialize the Vue instance
  new Vue({
    router,
    i18n,
    vuetify,
    pinia,
    async created() {
      // Providing plugin instances for global injection for Composition API
      provideAuth0(this.$auth0)
      provide(splitInjectionKey, this.$split)
      provide(ga4InjectionKey, this.$ga4)

      modules.locale.setLocale(modules.locale.locale)
      if (modules.auth.isTokenSet) {
        modules.auth.autoLogin()
      }
    },
    render: (h) => h(App),
  }).$mount('#app')
}

initApp()
