import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import { TableViewParameters } from '@/models/dto'
import { SystemParameterTableViewResult } from '@/models/dto/SystemParameter'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets a table view of system parameters based on the given parameters.
   *
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to the table view result containing a count and list of system parameters.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<SystemParameterTableViewResult>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const url = `/tables/systemParameters?${query}`
    return httpService.get(url)
  },
}
