<template>
  <div>
    <h3 class="subtitle-1">{{ $t('reservationDetail.documents.TITLE') }}</h3>
    <v-row
      class="bold-links margin-t-3 margin-x-0"
      :style="{ 'margin-bottom': '6px' }"
    >
      <a
        id="reservation-detail-link-view-invoice"
        class="font-bold"
        @click="isInvoiceDialogOpen = !isInvoiceDialogOpen"
      >
        {{ $t('reservationDetail.documents.VIEW_INVOICE') }}
      </a>
      <span
        v-if="insuranceUrl"
        class="font-bold text-border-gray margin-y-0 margin-x-3"
      >
        |
      </span>
      <a
        v-if="insuranceUrl"
        id="reservation-detail-link-view-insurance"
        class="font-bold"
        @click="isInsuranceDialogOpen = !isInsuranceDialogOpen"
      >
        {{ $t('reservationDetail.documents.VIEW_INSURANCE') }}
      </a>
      <span class="font-bold text-border-gray margin-y-0 margin-x-3">
        |
      </span>
      <a
        id="reservation-detail-link-view-w9"
        class="font-bold"
        @click="isW9DialogOpen = !isW9DialogOpen"
      >
        {{ $t('reservationDetail.documents.VIEW_W9') }}
      </a>
    </v-row>
    <ViewPdfModal
      :value="isOpen"
      :file-name="fileName"
      full-domain
      @input="close"
    />
  </div>
</template>

<script lang="ts">
import systemParameter from '@/store/modules/systemParameter'
import { pdfBaseUrl } from '@/utils/env'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ViewPdfModal from '@/components/ViewPdfModal.vue'

@Component({ components: { ViewPdfModal } })
export default class ReservationDetailDocuments extends Vue {
  @Prop({ type: String, required: false, default: '' })
  readonly insuranceUrl: string
  @Prop({ type: String, required: true }) readonly hash: string

  isInvoiceDialogOpen = false
  isInsuranceDialogOpen = false
  isW9DialogOpen = false

  close(): void {
    this.isInvoiceDialogOpen = false
    this.isInsuranceDialogOpen = false
    this.isW9DialogOpen = false
  }

  get isOpen(): boolean {
    return (
      this.isInvoiceDialogOpen ||
      this.isInsuranceDialogOpen ||
      this.isW9DialogOpen
    )
  }

  get fileName(): string {
    if (this.isInvoiceDialogOpen) {
      return this.invoiceUrl
    }
    if (this.isInsuranceDialogOpen) {
      return this.insuranceUrl
    }
    if (this.isW9DialogOpen) {
      return this.w9Url
    }
    return ''
  }

  get w9Url(): string {
    return systemParameter.w9Url
  }

  get invoiceUrl(): string {
    return `https://${pdfBaseUrl()}/pdf/charterup-invoice/reservations?reservationHashes=${encodeURIComponent(
      [this.hash].join('')
    )}`
  }
}
</script>
