import { HttpService } from './common/HttpService'
import { ClientCancellationInfoResultDTO, ClientCancellationClassificationResultDTO } from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

const CLIENT_CANCELLATION_ID = 17

export default {
  /**
   * Get cancellation penalty info for a given reservationId
   * @param reservationId - The reservationId to get the cancellation penalty info for
   * @returns ClientCancellationInfoResultDTO - The cancellation penalty info for the given reservationId
   */
  getCancellationPenaltyInfo(
    reservationId: number
  ): Promise<AxiosResponse<ClientCancellationInfoResultDTO>> {
    const url = `/clientCancellation/${reservationId}/cancellationPenaltyInfo`
    return httpService.get(url)
  },
  getCancellationClassifications(): Promise<AxiosResponse<ClientCancellationClassificationResultDTO>> {
    const url = `/classifications/type/cancellation?parentClassificationId=${CLIENT_CANCELLATION_ID}`
    return httpService.get(url)
  },
}
