import { AxiosResponse } from 'axios'
import { LinkShortenerResult } from '@/models/dto/LinkShortener'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  createShortLink(
    originalURL: string
  ): Promise<AxiosResponse<LinkShortenerResult>> {
    const url = '/links'
    const payload = { originalURL }

    return httpService.post(url, payload)
  },
}
