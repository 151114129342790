import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import { ChatUpdatePayload, ChatUpdateResult } from '@/models/dto/Chat'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Updates a conversation by its SID.
   *
   * @param conversationSid - The SID of the chat to be updated.
   * @returns A promise that resolves to the company's information.
   */
  updateByConversationSid(conversationSid: string, payload: ChatUpdatePayload): Promise<AxiosResponse<ChatUpdateResult>> {
    const url = `/chats/${conversationSid}`
    return httpService.patch(url, payload)
  },
}
