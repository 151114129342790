<template>
  <div>
    <div class="d-flex align-center justify-space-between margin-b-4">
    <h2 v-if="tripSummary.name">
      {{ tripSummary.name }}
    </h2>
    <v-spacer />
    <CUIcon
      v-if="closeable"
      view-box="0 0 24 24"
      width="24px"
      height="24px"
      @click="emit('close')"
    >
      close
    </CUIcon>
    </div>

    <v-row>
      <v-col cols="12" sm="6" md="12" class="order-1 order-sm-0">
        <SalesBotTripSummaryVehicles
          v-if="shouldShowVehicles"
          :vehicles="tripSummary.vehicles"
          class="margin-b-5"
        />
        <TripItineraryV2 v-if="!smAndDown" :itinerary-stops="tripSummary.stops" />
        <SalesBotTripSummaryDetails
          v-if="shouldShowDetails"
          :passengers="tripSummary.passengers"
          :specialRequirements="tripSummary.specialRequirements"
          :amenities="tripSummary.amenities"
        />
      </v-col>
      <v-col v-if="smAndDown" cols="12" sm="6" md="12" class="order-0 order-sm-1">
        <h3 class="margin-b-3">Itinerary</h3>
        <div>
          <SalesBotMap
            class="w-full margin-b-3"
            :waypoints="waypoints"
            max-height="365px"
            height="365px"
            no-fade
            border-radius="8px"
            :manualRefreshToggle="isModalOpen"
          />
        </div>
        <TripItineraryV2 :itinerary-stops="tripSummary.stops" />
      </v-col>
      <v-col v-if="smAndDown && salesBotStore?.showProceedToBooking" cols="12" class="order-2">
        <SalesBotToBookingButton
          id="sales-bot-trip-summary-to-booking-button"
          :disabled="!areAllStepsValid"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import TripItineraryV2 from "@/components/TripItineraryV2.vue";
import SalesBotMap from '@/components/SalesBotMap.vue'
import SalesBotTripSummaryVehicles from '@/components/SalesBotTripSummaryVehicles.vue'
import SalesBotTripSummaryDetails from '@/components/SalesBotTripSummaryDetails.vue'
import SalesBotToBookingButton from '@/components/SalesBotToBookingButton.vue'

import { useSalesBotStore } from "@/store/modules/salesBot";
import { computed } from "vue";
import { useDisplay } from '@/composables/useDisplay'

const salesBotStore = useSalesBotStore()
const display = useDisplay()

const emit = defineEmits(['close'])

const props = withDefaults(
  defineProps<{
    closeable?: boolean,
    isModalOpen?: boolean
  }>(),
  {
    closeable: false,
    isModalOpen: false
  }
)

const areAllStepsValid = computed<boolean>(() => {
  const accordionSteps = salesBotStore?.accordionSteps?.value ?? []
  return accordionSteps.every(({isValid}) => isValid)
})

const tripSummary = computed(() => {
  return salesBotStore.tripSummary
})

const waypoints = computed<LatLng[]>(() => {
  const waypoints: LatLng[] = []

  salesBotStore.stops.forEach((stop) => {
    const address = stop.address
    if (address?.lat && address?.lng) {
      waypoints.push({ lat: address.lat, lng: address.lng })
    }
  })
  return waypoints
})

const smAndDown = computed(() => display.smAndDown.value)
const smOnly = computed(() => display.smOnly.value)
const xsOnly = computed(() => display.xs.value)

const shouldShowVehicles = computed(() => tripSummary.value.vehicles.length)
const shouldShowDetails = computed(() => tripSummary.value.passengers || tripSummary.value.specialRequirements.length || tripSummary.value.amenities.length)



</script>
