import axios from 'axios'

/**
 * Retrieves the subdomain from the current window's host.
 *
 * The subdomain is extracted from the host part of the URL, which is split
 * by the dot (.) character. If the host is in the format of
 * "subdomain.domain.com", this function will return "subdomain".
 * If there is no subdomain, it will return an empty string.
 *
 * @returns The subdomain of the current host as a string.
 *
 * Note: This method is only exported for testing purposes and should not
 * be used outside this utility file.
 */
export const getHostSubdomain = (): string => {
  const host = process.env.VUE_APP_BASE_URL_OVERRIDE || window.location.host
  const hostSubdomain = host.split(':')[0].split('.')[0]
  return hostSubdomain
}

export const EnvironmentName = {
  unknown: 'unknown',
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const
export type EnvironmentName = (typeof EnvironmentName)[keyof typeof EnvironmentName]

/**
 * Return the environment that this app is running in.
 */
export const resolveEnvironment = (): EnvironmentName => {
  // TODO: Use this everywhere we need to resolve the environment, to ensure
  // that we're using the same algorithm.
  // Use the hostname from the browser location bar.
  const browserHostname = window.location.host
  const hostSubdomain = getHostSubdomain()

  // Map environment to its corresponding string value
  const envMap: Record<string, EnvironmentName> = {
    'dev': EnvironmentName.dev,
    'auto': EnvironmentName.staging,
    'app': EnvironmentName.production,
    'localhost': EnvironmentName.local,
  }

  const env: EnvironmentName = envMap[hostSubdomain] ?? EnvironmentName.unknown
  if (env === EnvironmentName.unknown) {
    throw new Error("Could not determine environment name from browser location " + browserHostname)
  }

  return env
}

/**
 * Returns the base URL for the internal API.
 *
 * @returns The base URL.
 */
export const baseUrl = (): string => {
  return '/api'
}

/**
 * Returns the base URL for the PDF API.
 *
 * This function constructs the base URL specifically for the PDF service
 * by appending 'pdf' as a prefix to the base URL.
 *
 * @returns The base URL for the PDF API.
 */
export const pdfBaseUrl = (): string => {
  const baseDomain = 'coachrail.com'
  const urlPrefix = 'pdf.'

  let host
  if (process.env.VUE_APP_BASE_URL_OVERRIDE) {
    host = process.env.VUE_APP_BASE_URL_OVERRIDE
  } else {
    let hostSubdomain = getHostSubdomain()
    if (hostSubdomain === 'localhost') {
      hostSubdomain = 'dev'
    }
    host = `${hostSubdomain}.${baseDomain}`
  }

  const url = `${urlPrefix}${host}`
  return url
}

/**
 * Returns the base URL for the current host.
 *
 * @returns The base URL for the current host.
 */
export const hostBaseUrl = () => {
  let host
  const hostSubdomain = getHostSubdomain()
  if (hostSubdomain === 'localhost') {
    host = 'dev.charterup.com'
  } else {
    host = `${hostSubdomain}.charterup.com`
  }
  return `https://${host}`
}

/**
 * Provides the Split config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const splitKey = () => {
  const env = resolveEnvironment()
  if (env === EnvironmentName.local || env === EnvironmentName.dev) {
    return 'q65ir5g1n7pek8dn6f7rm4n0ua6patbsv69p'
  } else if (env === EnvironmentName.staging) {
    return 'q17bli4sobmq2riile3p8vm6vjdg9rkkcgr4'
  } else if (env === EnvironmentName.production) {
    return '4mfo5hbqg0cbkifhoocbtj26jukglmlbf1o9'
  }
}

/**
 * Provides the Finix config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const finixConfig = (): { environment: 'sandbox' | 'live', applicationId: string } => {
  const env = resolveEnvironment()
  if (env === EnvironmentName.local || env === EnvironmentName.dev) {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (env === EnvironmentName.staging) {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (env === EnvironmentName.production) {
    return {
      environment: "live",
      applicationId: "APtkkr768DDXcQttsm2pahvz"
    }
  }

  return null

}

/**
 * Provides the Auth0 config based on the subdomain of the current host.
 * @returns The Auth0 config based on the subdomain.
 */
export const auth0Config = (): {
  domain: string
  clientId: string
  audience: string
} => {
  const env = resolveEnvironment()
  const AUDIENCE = 'charterup'
  if (env === EnvironmentName.local || env === EnvironmentName.dev) {
    return {
      domain: 'login.dev.charterup.com',
      clientId: 'cMJNCyJ21q36wRF2DR5VitWaOvDWhYJ5',
      audience: AUDIENCE
    }
  }
  if (env === EnvironmentName.staging) {
    return {
      domain: 'login.auto.charterup.com',
      clientId: 'cFG7RIOTXvdEAm367Y1S4HhVpW8I22hF',
      audience: AUDIENCE
    }
  }
  if (env === EnvironmentName.production) {
    return {
      domain: 'login.charterup.com',
      clientId: 'ACly3u8Z0oQDtNdAgk7YjMXf6oCNhegp',
      audience: AUDIENCE
    }
  }
}
