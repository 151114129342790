import { PlaceDetail, PlaceSearchResult } from '@/models/dto'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import { LatLng } from '@/models/LatLng'
const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves the place search results for a given search term.
   *
   * @param term - The search term to retrieve the place search results for.
   * @param includedCountries - The countries to include in the results
   * @param coordinate - Optional LatLng param to bias search results around a given location
   *
   * @returns A promise that resolves to the place search results for the specified search term.
   */
  search(
    term: string,
    includedCountries: string[],
    coordinate?: LatLng,
  ): Promise<AxiosResponse<PlaceSearchResult>> {
    const countries = includedCountries
      ? `&country=${includedCountries.join(',')}`
      : ''

    const biasCoordinate = coordinate?.lat && coordinate?.lng
      ? `&lat=${coordinate.lat}&lng=${coordinate.lng}&radius=50000`
      : ''

    const url = `/places?term=${term}${countries}${biasCoordinate}`
    return httpService.get(url)
  },
  /**
   * Retrieves the address for a given place ID.
   * @param placeId - The place ID to retrieve the address for.
   * @returns A promise that resolves to the address for the specified place ID.
   */
  byId(placeId: string): Promise<AxiosResponse<PlaceDetail>> {
    const url = `/places/${placeId}`
    return httpService.get(url)
  },
}
