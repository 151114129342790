import {
  AddRiderPayload,
  ApiResult,
  InviteRiderPayload,
  TableViewParameters,
  TableViewResult,
  UpdateRiderPayload,
} from '@/models/dto'
import { TableViewRider } from '@/models/dto/TableViewRider'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import { RiderTicketHashResult } from '@/models/dto/RiderTicketHash'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets a list of riders using the given table view parameters.
   *
   * @param params - The table view parameters to use to get the riders.
   * @returns A promise that resolves to the result containing the list of riders.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewRider>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const url = `/riders?${query}`
    return httpService.get(url)
  },
  /**
   * Imports riders for the customer with the given customer account ID.
   *
   * @param customerAccountId - The customer account ID to use to import riders.
   * @param payload - The riders to import.
   * @returns A promise that resolves to the result of importing the riders.
   */
  import(
    customerAccountId: number,
    payload: FormData,
    addAll = false,
    sendInviteEmail = false
  ): Promise<AxiosResponse<ApiResult>> {

    let url = `/rider/import/${customerAccountId}`
    if (addAll) {
      url = url.concat('?addAll=true')
    } else {
      url = url.concat('?addAll=false')
    }
    if (sendInviteEmail) {
      url = url.concat('&sendInviteEmail=true')
    }
    return httpService.post<ApiResult, FormData>(url, payload)
  },
  /**
   * Gets a rider import template
   *
   * @returns a promise that resolves to an AxiosResponse containing a Blob
   */
  importRiderTemplate(): Promise<AxiosResponse<Blob, any>> {

    const url = `/rider/import/template`
    return httpService.get(url, { responseType: 'blob' })
  },
  /**
   * Creates a new rider with the given payload.
   *
   * @param payload - The payload containing the information for the new rider.
   * @returns A promise that resolves to the result of creating the rider.
   */
  create(payload: AddRiderPayload): Promise<AxiosResponse<ApiResult>> {

    const url = '/rider'
    return httpService.post<ApiResult, AddRiderPayload>(url, payload)
  },
  /**
   * Updates the rider with the given payload.
   *
   * @param payload - The payload containing the updated information for the rider.
   * @returns A promise that resolves to the result of updating the rider.
   */
  update(payload: UpdateRiderPayload): Promise<AxiosResponse<ApiResult>> {

    const url = '/rider'
    return httpService.patch<ApiResult, UpdateRiderPayload>(url, payload)
  },
  /**
   * Deletes the rider with the given ID.
   *
   * @param payload - The payload containing the ID of the rider to delete.
   * @returns A promise that resolves to the result of deleting the rider.
   */
  delete(payload: any): Promise<AxiosResponse<ApiResult>> {

    const url = `/rider/delete/${payload.riderId}`
    return httpService.delete(url, payload)
  },
  /**
   * Sends an invite email to the rider with the given id.
   *
   * @param payload - The payload containing the rider's id.
   * @returns A promise that resolves to the result of sending an invite email to the rider.
   */
  sendInviteEmail(payload: InviteRiderPayload): Promise<AxiosResponse> {

    const url = `/rider/invite`
    return httpService.post<ApiResult, InviteRiderPayload>(url, payload)
  },
  /**
   * Retrieves a paginated list of RiderRoutes based on the specified TableViewParameters
   *
   * @param params - an object containing page, pageSize, sorts, and filters parameters for the request
   * @returns a promise that resolves to an AxiosResponse containing a TableViewResult of TableViewRiders
   */
  routesTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewRider>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const url = `/riderRoutes?${query}`
    return httpService.get(url)
  },
  /**
   * Returns a blob of rider scans for a customer account within a specified date range.
   *
   * @param payload - The payload containing the customer account ID and the date range.
   * @returns a promise that resolves to an AxiosResponse containing a Blob
   */
  routesTableExport(payload): Promise<AxiosResponse<Blob, any>> {

    const url = `/charterup/v1/customer-accounts/${payload.customerAccountId}/export-rider-scans`
    return httpService.post(url, payload.dateRange)
  },
  /**
   * Retrieves route metrics for a given customer account and contract within a specified date range.
   *
   * @param payload - The payload containing the customer account ID, contract ID, and the date range.
   * @returns A promise resolving to an AxiosResponse containing the retrieved metrics.
   */
  routeMetrics(payload): Promise<AxiosResponse> {

    const url = `/charterup/v1/customer-accounts/${payload.customerAccountId}/contracts/${payload.contractId}/route-metrics`
    return httpService.post(url, payload.dateRange)
  },
  /**
   * Retrieves rider ticket hash for a given rider.
   *
   * @param riderId - The ID of the rider to retrieve the hash for.
   * @returns A promise resolving to an AxiosResponse containing the rider ticket hash.
   */
  getRiderTicketHash(
    riderId: number
  ): Promise<AxiosResponse<RiderTicketHashResult>> {

    const url = `/rider/riderTicketHash/${riderId}`
    return httpService.get(url)
  },
  /**
   * Retrieves a zip file with QR codes for all the riders with the given customer account ID.
   *
   * @param customerAccountId - The customer account ID to use to get riders.
   * @returns A promise resolving to an AxiosResponse containing a byte[] of the zipped file.
   */
  getQRCodes(customerAccountId: number): Promise<AxiosResponse> {

    const url = `/rider/getAllQRCodes/${customerAccountId}`
    return httpService.get(url, { responseType: 'blob' })
  },
  /**
   * Retrieves a list of rider routes for the rider with the given rider ID.
   *
   * @param riderId - The rider ID to get the rider.
   * @returns A promise resolving to an AxiosResponse containing a list of rider routes.
   */
  getHistoricalData(riderId: number): Promise<AxiosResponse> {

    const url = `/rider/historicalData/${riderId}`
    return httpService.get(url)
  },
}
